import {
  Box,
  BoxProps,
} from '@chakra-ui/react';
import SwiperCore, {
  Pagination,
  Navigation,
  Autoplay
} from 'swiper';
import {
  Swiper,
  SwiperSlide
} from 'swiper/react';
import { AvatarDescriptionCard } from '@/components/AvatarDescriptionCard/AvatarDescriptionCard';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/bundle';
import { getPageInfo } from '@/config/pages';
import { BrandCard } from '@/features/brand/types/brand';

SwiperCore.use([Pagination, Navigation, Autoplay]);

interface SpBrandSwiperProps extends BoxProps {
  brandSwiper: BrandCard[];
  sliderHeight: string;
  swiperColor?: string;
}

export const SpBrandSwiper = ({
  brandSwiper,
  sliderHeight,
  swiperColor = 'brand.500',
  ...props
}: SpBrandSwiperProps) => {

  return (
    <Box
      {...props}
    >
      <Swiper
        slidesPerView={1}
        centeredSlides={true}
        navigation={{
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next'
        }}
        autoplay={{
          delay: 10000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
          reverseDirection: false
        }}
        loop={brandSwiper.length > 1}
      >
        {
          brandSwiper.map(({
            brandImageUrl,
            brandAltText,
            brandHeading,
            brandSubHeading,
            brandDescription,
            pagePath,
            slug,
            name
          }) =>
            <SwiperSlide
              key={brandAltText}
            >
              <AvatarDescriptionCard
                imageProps={{
                  imageUrl: brandImageUrl,
                  imageAltText: brandAltText,
                }}
                headingProps={{
                  heading: brandHeading,
                  headingTextColor: swiperColor
                }}
                subHeading={brandSubHeading}
                descriptionProps={{
                  description: brandDescription
                }}
                pageInfo={pagePath && getPageInfo(pagePath, { slug: slug, name: name })}
              />
            </SwiperSlide>
          )
        }
      </Swiper>
      <Box
        className={'swiper-button-prev'}
        color={swiperColor}
        pr={40}
        mt={sliderHeight}
        _after={{ fontSize: '30px' }}
      />
      <Box
        className={'swiper-button-next'}
        color={swiperColor}
        pl={40}
        mt={sliderHeight}
        _after={{ fontSize: '30px' }}
      />
    </Box>
  );
};
