import {
  Box,
  BoxProps
} from '@chakra-ui/react';
import { ReactNode } from 'react';

interface HeadingSubTextProps extends BoxProps {
  children: ReactNode;
}

export const HeadingSubText = ({
  children,
  ...props
}: HeadingSubTextProps) => {
  return (
    <Box
      lineHeight={'210%'}
      fontWeight={500}
      fontStyle={'normal'}
      letterSpacing={'0.045em'}
      textStyle={'h5'}
      whiteSpace={'pre-line'}
      pt={3}
      pb={3}
      {...props}
    >
      {children}
    </Box>
  );
};
